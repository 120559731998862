﻿<template>
    <div v-if="model">
        <template>
            <input
                :id="inputId"
                v-model="value"
                :class="inputClasses"
                :max="max"
                :min="min"
                :name="inputName"
                :step="step"
                :type="type"
                v-on:change="handleChange"
                v-on:input="handleInput"
            />
            <small :class="errorClasses">{{ errorMessage }}</small>
        </template>
    </div>
</template>
<script>
function debounce(fn, delay) {
    var timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        var args = arguments;
        var that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
}

export default {
    name: "inputFieldNumeric",
    data: function () {
        return {
            value: null,
            isFocused: false,
            errorMessage: null,
            processData: debounce(function (newVal) {
                //this.value = newVal
                this.value = newVal ? newVal[this.fieldName] : null;
            }, 1000),
        };
    },
    props: {
        model: {
            default: null,
        },
        id: {
            default: null,
        },
        label: {
            default: "",
        },
        fieldName: {
            default: "",
        },
        type: {
            default: "text",
        },
        min: {
            default: null,
        },
        max: {
            default: null,
        },
        step: {
            default: 1,
        },
        required: {
            type: Boolean,
            default: false,
        },
        inputClasses: {
            default: "",
        },
        labelClasses: {
            default: "",
        },
        errorClasses: {
            default: "field-validation-error text-danger",
        },
    },
    computed: {
        inputId: function () {
            return this.id + "_" + this.fieldName;
        },
        inputName: function () {
            return this.id + "_" + this.fieldName;
        },
    },
    created: function () {
        this.value = this.model ? this.model[this.fieldName] : null;
    },
    watch: {
        model(newVal) {
            this.processData(newVal);
        },
    },
    methods: {
        getValue: function () {
            return this.value;
        },
        handleInput: function () {
            this.errorMessage = "";
        },
        handleChange: function () {
            this.model[this.fieldName] = this.value;

            this.validate((valid, message) => {
                this.errorMessage = message;

                if (valid) {
                    this.$emit("input", this.value);
                }
            });
        },
        onFocus(e) {
            this.isFocused = true;
            this.$emit("focus", e);
        },
        onBlur(e) {
            this.isFocused = false;
            this.$emit("blur", e);
        },
        validate: function (callback) {
            var me = this;
            var value = this.getValue();
            var name = this.inputName;

            if (!value) {
                callback(false, "Must input" + this.inputName);
            } else {
                if (this.min && value < this.min) {
                    callback(
                        false,
                        this.inputName + "must be greater than min value " + this.min
                    );
                }

                if (this.max && value > this.max) {
                    callback(
                        false,
                        this.inputName + "must be less than max value " + this.max
                    );
                }
            }

            callback(true, null);
        },
    },
};
</script>
